import { Component, OnInit, Input } from '@angular/core';
import { UnifiedOrderMerge } from '../../core/schema';
import { unifiedOrderVendorMappings } from '../../core/string-map';
import { ClipboardService } from '../../core/clipboard.service';
import { normalizeTel } from '../../core/util';
import { unifiedOrderDeliveryTypeMappings } from '../../core/string-map';

@Component({
  selector: 'app-order-contact',
  templateUrl: './order-contact.component.html',
  styleUrls: ['./order-contact.component.scss']
})
export class OrderContactComponent implements OnInit {
  @Input() minimalView = false;
  @Input() showTelAndPrice = true;
  @Input() prefixMsg = '';

  order: UnifiedOrderMerge;
  @Input('order') set _order(order: UnifiedOrderMerge) {
    this.order = order;

    if (!['TAKEOUT', 'BAERA'].includes(order.deliveryType) && order.address_sigungu && order.address_dong && order.address_jibun) {
      this.orderAddress = `${order.address_sigungu} ${order.address_dong} ${order.address_jibun}`;
      this.orderAddressDetail = order.address_detail;
      this.roadAddress = `${order.address_road}`;
    } else {
      this.orderAddress = unifiedOrderDeliveryTypeMappings[order.deliveryType];
      this.orderAddressDetail = '';
    }

    this.orderVendorStr = unifiedOrderVendorMappings[order.orderVendor];
  }

  normalizeTel = normalizeTel;

  orderAddress: string;
  roadAddress: string;
  orderAddressDetail: string;
  deliveryAddress: string;
  deliveryAddressDetail: string;
  deliveryPrice: number = null; // 배송 가격이 바뀌는 경우가 가끔있다.
  deliveryPaymentMethod = '';
  isDifferentAddress = false;

  orderVendorStr: string;
  deliveryChannelStr: string;

  constructor(
    private clipboardService: ClipboardService
  ) { }

  ngOnInit() {
  }

  copyToClipboard(event: MouseEvent, text: string = null) {
    const el = event.target as HTMLElement;
    // nested element의 경우에 상위에서 복사가 또 발생하는 것을 막는다.
    event.stopPropagation();

    if (text) {
      this.clipboardService.copyTextToClipboard(text, el);
    } else {
      this.clipboardService.copyTextToClipboard(el.innerText, el);
    }
  }
}
