import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from './auth-guard.service';
import { InitGuardService } from './init-guard.service';

import { SitesComponent } from './pages/sites/sites.component';
import { OrderFormComponent } from './form/order-form/order-form.component';
import { FirstGuardService } from './first-guard.service';
import { LandingComponent } from './landing.component';
import { ErrorComponent } from './pages/error/error.component';

const routes: Routes = [
  {
    path: 'error',
    pathMatch: 'full',
    component: ErrorComponent
  },
  {
    path: '',
    canActivate: [InitGuardService],
    canActivateChild: [AuthGuardService],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'gk-kangnam',
      },
      {
        path: ':site/order',
        pathMatch: 'full',
        component: OrderFormComponent
      },
      {
        path: 'landing',
        pathMatch: 'full',
        component: LandingComponent,
        canActivate: [FirstGuardService]
      },
      {
        path: ':site',
        pathMatch: 'full',
        component: SitesComponent
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'gk-kangnam'
  }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule]
})
export class AppRoutingModule {}
