import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AuthService } from './core/auth.service';
import { UserService } from './core/user.service';
import { LogService } from './core/log.service';
import { AddressService } from './core/address.service';
import { ConfService } from './core/conf.service';
import { BaeminUserDetailShopService } from './core/baemin-user-detail-shop.service';
import { AlertNoticeService } from './shared/alert-notice/alert-notice.service';
import { LoadingService } from './shared/loading/loading.service';
import { GtagService } from './core/gtag.service';
import { VersionService } from './core/version.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  subscription: Subscription;
  routeAnimation = true;
  email = 'orderhere1@toe.cloud';

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthService,
    private userService: UserService,
    private logService: LogService,
    private addressService: AddressService,
    private confService: ConfService,
    private versionService: VersionService,
    private baeminUserDetailShopService: BaeminUserDetailShopService,
    private alertNoticeService: AlertNoticeService,
    private loadingService: LoadingService,
    private gtagService: GtagService,
    private router: Router,
  ) {
    this.initializeApp();
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // 홈화면으로 돌아오는 경우 Route Animation 제거
        const urls = event.url.split('/'); 
        this.routeAnimation = !(urls.length < 3);

        this.gtagService.page(event.urlAfterRedirects);
      }
    })
  }

  async initializeApp() {
    await this.platform.ready();
    this.statusBar.styleDefault();
    this.splashScreen.hide();
  }

  ngOnInit() {
    this.loadingService.presentLoading();
    this.signIn();
  }

  async signIn() {
    try {
      const isSignIn = await this.authService.signinWithEmailAndPassword(this.email, 'B71B7852-6252-4CCA-A3DD-17C81DFF16B3');
      if (isSignIn) {
        this.userService.observe(this.email);
        this.confService.observeOrganizationConf();
        this.confService.observeMangoConf();
        this.baeminUserDetailShopService.observe();

        let localAddresses = 'unknown';
        try {
          localAddresses = (await this.addressService.findLocalAddress()).join();
        } catch (err) {
          console.error('Fail to local IP address');
        }
        const publicAddress = await this.addressService.findPublicAddress();
        const myVersion = this.versionService.myVersion;
        this.logService.info(`로그인 성공. myVersion = ${myVersion}, referrer = ${document.referrer}, local = ${localAddresses}, remote = ${publicAddress.ip}`, this.email );
        // 로그인된 사용자만 이벤트 페이지로 이동
        this.router.navigate(['gk-kangnam']);
      }
    } catch (error) {
      this.logService.error(`로그인 실패. error = ${error}`);
      this.loadingService.dismissLoading();
      this.alertNoticeService.noticeAlert(error);

      // 로그인 실패시 로그인 다시시도 버튼이 있는 페이지로 이동
      this.router.navigate(['/auth/login']);
    }
  }
}
