import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { UnifiedOrderFood, UnifiedOrderFoodOpt, UnifiedMenuFood } from './schema';
import { postProcessFoods } from '../core/util';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {
  shopNo: string;
  unifiedOrderFoods: UnifiedOrderFood[] = [];
  latestUnifiedOrderFoodsSubject = new BehaviorSubject<UnifiedOrderFood[]>([]);

  constructor() { }

  observe(): Observable<UnifiedOrderFood[]> {
    return this.latestUnifiedOrderFoodsSubject.asObservable();
  }

  emptyCart() {
    this.unifiedOrderFoods = [];
    this.shopNo = undefined;

    this.latestUnifiedOrderFoodsSubject.next(this.unifiedOrderFoods);
  }

  addFood(food: UnifiedMenuFood, shopNo: string, foodQty: number) {
    this.shopNo = shopNo;

    const foodOpts: UnifiedOrderFoodOpt[] = [];
    for (const foodOptGroup of food.foodOptGroups) {
      for (const foodOpt of foodOptGroup.foodOpts) {
        if (foodOpt._optQty > 0) {
          foodOpts.push({
            optGrpName: foodOptGroup.optGrpName,
            optName: foodOpt.optName,
            optPrice: foodOpt.optPrice,
            optQty: foodOpt._optQty
          });
        }
      }
    };

    const unifiedOrderFood: UnifiedOrderFood = {
      foodName: food.foodName,
      foodOpts,
      foodOrdPrice: foodOpts.reduce((acc, foodOpt) => acc + foodOpt.optPrice * foodOpt.optQty, 0) * foodQty,
      foodQty: foodQty,
      mergedName: food.foodName  // 나중에 변경한다.
    };

    
    this.unifiedOrderFoods.push(unifiedOrderFood);
    this.unifiedOrderFoods = postProcessFoods(this.unifiedOrderFoods);
    
    this.latestUnifiedOrderFoodsSubject.next(this.unifiedOrderFoods);
  }

  removeFood(index: number) {
    this.unifiedOrderFoods.splice(index, 1);

    this.latestUnifiedOrderFoodsSubject.next(this.unifiedOrderFoods);
  }

  increaseFoodQty(index: number) {
    const unifiedOrderFood = this.unifiedOrderFoods[index];

    unifiedOrderFood.foodQty++;
    unifiedOrderFood.foodOrdPrice = unifiedOrderFood.foodOpts.reduce((sum, foodOpt) => sum + foodOpt.optQty * foodOpt.optPrice, 0) * unifiedOrderFood.foodQty;

    this.unifiedOrderFoods[index] = unifiedOrderFood;
    this.latestUnifiedOrderFoodsSubject.next(this.unifiedOrderFoods);
  }

  decreaseFoodQty(index: number) {
    const unifiedOrderFood = this.unifiedOrderFoods[index];

    if (unifiedOrderFood.foodQty < 2) {
      this.removeFood(index);
    } else {
      unifiedOrderFood.foodQty--;
      unifiedOrderFood.foodOrdPrice = unifiedOrderFood.foodOpts.reduce((sum, foodOpt) => sum + foodOpt.optQty * foodOpt.optPrice, 0) * unifiedOrderFood.foodQty;
      
      this.unifiedOrderFoods[index] = unifiedOrderFood;
      this.latestUnifiedOrderFoodsSubject.next(this.unifiedOrderFoods);
    }
  }
}
