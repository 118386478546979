import fecha from 'fecha';
import { get_request_auth } from '../../core/sms-api';
import { get_confirm_auth } from '../../core/sms-api';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, FormControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Subscription, throwError } from 'rxjs';

import { UnifiedOrderFood, UnifiedOrder, UnifiedOrderContext, UnifiedOrderStatusCode, UnifiedOrderDoc } from '../../core/schema';
import { Message } from '../../core/schema-message';

import { UnifiedOrderService } from '../../core/unified-order.service';
import { ShoppingCartService } from '../../core/shopping-cart.service';
import { MessageService } from '../../core/message.service';
import { ToastNoticeService } from '../../shared/toast-notice/toast-notice.service';
import { AlertNoticeService } from '../../shared/alert-notice/alert-notice.service';
import { LoadingService } from '../../shared/loading/loading.service';
import { ConfService } from '../../core/conf.service';
import { LogService } from 'src/app/core/log.service';
import { GtagService } from 'src/app/core/gtag.service';

import { EventList } from '../../core/schema-mango';
@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.scss']
})
export class OrderFormComponent implements OnInit, OnDestroy {
  isClosed = true;
  msgClosed = '';

  validJibun = new Map<string, { min: number, max: number }[]>([
    ['역삼동', [
      { min: 601, max: 675 },
      { min: 677, max: 714 },
      { min: 716, max: 767 },
      { min: 769, max: 805 },
      { min: 808, max: 840 },
      { min: 858, max: 858 }
    ]],
    ['서초동', [
      { min: 1301, max: 1339 }
    ]],
  ]);

  unifiedOrderFoods: UnifiedOrderFood[] = [];
  room: string;
  shop: EventList;
  shopNo: string;

  // UI 표시용
  totalQty = 0;
  totalAmount = 0;
  availableLocalStorage = true;
  
  orderForm: FormGroup;
  currentSite: string;
  
  // Data model
  unifiedOrder: Partial<UnifiedOrder> = {};
  unifiedOrderContext: Partial<UnifiedOrderContext> = {};

  sessionId: string;
  // authCode: string;
  confirmAuth = false;

  private shoppingCartsubscription: Subscription = null;
  private mangoSubscription: Subscription = null;

  constructor(
    private shoppingCartService: ShoppingCartService,
    private confService: ConfService,
    private logService: LogService,
    private unifiedOrderService: UnifiedOrderService,
    private toastNoticeService: ToastNoticeService,
    private loadingService: LoadingService,
    private alertNoticeService: AlertNoticeService,
    private messageService: MessageService,
    private gtagService: GtagService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    const state = this.location.getState();
    this.shop = (state as { shop: EventList }).shop;
    this.currentSite = this.route.snapshot.paramMap.get('site');
    this.shopNo = this.shoppingCartService.shopNo;

    this.observeMango();
    this.observeShoppingCart();

    this.initModel();
    this.resetForShop();

    this.buildForm();
    this.observeUserTel();
  }

  ngOnDestroy() {
    if (this.shoppingCartsubscription) {
      this.shoppingCartsubscription.unsubscribe();
    }
    if (this.mangoSubscription) {
      this.mangoSubscription.unsubscribe();
    }

    this.shoppingCartService.emptyCart();
  }

  private observeMango() {
    this.mangoSubscription = this.confService.latestMangoConfSubject.subscribe(mango => {
      this.isClosed = mango.isClosed;
      this.msgClosed = mango.msgClosed;
    })
  }

  private observeShoppingCart() {
    this.shoppingCartsubscription = this.shoppingCartService.latestUnifiedOrderFoodsSubject.subscribe(unifiedOrderFoods => {
      this.unifiedOrderFoods = unifiedOrderFoods;

      this.totalAmount = this.unifiedOrderFoods.reduce((sum, food) => sum + food.foodOrdPrice, 0);
      this.totalQty = this.unifiedOrderFoods.reduce((sum, food) => sum + food.foodQty, 0);
    });
  }

  /*******************************************************************
   * face에서 copy한 내용
   *******************************************************************/
  initModel() {
    // Data model
    this.unifiedOrder = {...this.unifiedOrder, ...{
      organization: 'ghostkitchen',
      site: 'gk-kangnam',
      room: '',
      shopNo: '',
      shopName: '',
      orderChannel: 'app', //
      orderVendor: 'ghostkitchen',
      deliveryType: 'DELIVERY',
      instanceNo: '', // 데몬의 정보를 
      orderNo: '', 
      orderDate: fecha.format(new Date(), 'YYYY-MM-DDTHH:mm:ss+0900'), // 나중에 최종 적으로 업데이트한다.
      orderStatusCode: UnifiedOrderStatusCode.NEW,
      orderAmount: 0,
      deliveryTip: 3000,
      deliveryMinutes: 0,
      paymentMethod: '후불카드',
      userTel: '',
      orderMsg: '',

      address_key: '',
      address_detail: '',
      address_sido: '서울특별시',
      address_sigungu: '서초구',
      address_dong: '서초동',
      address_jibun: '',
      address_dongH: '',
      address_road: '',
      address_building_name: '',
      address_location: {
        lon: 0,
        lat: 0
      },
      vroong: {
        dest_sigungu: '',
        dest_legal_eupmyeondong: '',
        dest_admin_eupmyeondong: '',
        dest_ri: '',
        dest_beonji: '',
        dest_road: '',
        dest_building_number: '',
      },
    }};

    this.unifiedOrderContext = {...this.unifiedOrderContext, ...{
      eventDiscount: 0,
    }};
  }

  resetForShop() {
    // initModelForShop
    this.unifiedOrder.room = this.shop.room;
    this.unifiedOrder.shopName = this.shop.shopName;
    this.unifiedOrder.shopNo = this.shop.shopNo;
  }

  /**
   * 최초에 한 번만 실행
   */
  buildForm() {
    this.orderForm = this.fb.group({
      userTel: [ this.unifiedOrder.userTel, this.userTelValidator() ],
      address_dong: this.unifiedOrder.address_dong,
      address_jibun: [ this.unifiedOrder.address_jibun, this.addressJibunValidator() ],
      address_detail: this.unifiedOrder.address_detail,
      orderMsg: this.unifiedOrder.orderMsg,
      authCode: '',
      paymentMethod: this.unifiedOrder.paymentMethod
    }, { validators: this.formValidator() }); // cross field validation : https://angular.io/guide/form-validation#cross-field-validation
  }

  /**
   * UI 내용을 unifiedOrder에 반영
   */
  updateModel() {
    this.unifiedOrder = {...this.unifiedOrder, ...{
      userTel: this.orderForm.get('userTel').value,
      address_dong: this.orderForm.get('address_dong').value,
      address_jibun: this.orderForm.get('address_jibun').value,
      address_detail: this.orderForm.get('address_detail').value,
      orderMsg: this.orderForm.get('orderMsg').value,
      paymentMethod: this.orderForm.get('paymentMethod').value,
    }};

    if (this.unifiedOrder.address_dong === '역삼동') {
      this.unifiedOrder.address_sigungu = '강남구';
    } else if (this.unifiedOrder.address_dong === '서초동') {
      this.unifiedOrder.address_sigungu = '서초구';
    }
  }

  /**
   * userTel에 변화가 있으면 포맷을 자동 적용한다.
   */
  observeUserTel() {
    const formControl = this.orderForm.get('userTel');

    formControl.valueChanges.forEach(value => {
      const normalizedTel = this.normalizeTel(value);
      if (value !== normalizedTel) {
        this.orderForm.get('userTel').setValue(normalizedTel);
      }
    });
  }

  /**
   * 입력 과정 중에 자동으로 -를 붙여준다.
   * util.ts에 있는 noramlizeTel과는 쓰임이 다르다.
   */
  normalizeTel(telNo: string) {
    // 숫자 이외에는 모두 제외한다.
    telNo = telNo.replace(/[^0-9]/g, '');

    // debugLog(`1. telNo = ${telNo}`);

    if (telNo[0] !== '0') {
      return '';
    }
    // 2번째 숫자가 허용되지 않는 숫자라면 거부
    if (telNo[1] !== '1' && telNo[1] !== '2'  && telNo[1] !== '5' && telNo[1] !== '7') {
      return telNo[0];
    }
    // 010, 050, 070 이 아니고 051같은 경우는 거부
    // if ((telNo[1] === '1' || telNo[1] === '5' || telNo[1] === '7') && telNo[2] !== '0' ) {
    //   return `${telNo[0]}${telNo[1]}`;
    // }

    if (telNo.match(/^010|050|070/)) {
      // 국번이 0이나 1로 시작하지 않는다.
      if (telNo[3] === '0' || telNo[3] === '1') {
        return telNo.substr(0, 3);
      }

      if (telNo.length === 12) {
        return `${telNo.substr(0, 4)}-${telNo.substr(4, 4)}-${telNo.substr(8, 4)}`;
      } else if (telNo.length > 7) {
        return `${telNo.substr(0, 3)}-${telNo.substr(3, 4)}-${telNo.substr(7, 4)}`;
      } else if (telNo.length > 3) {
        return `${telNo.substr(0, 3)}-${telNo.substr(3, 4)}`;
      } else {
        return telNo;
      }
    } else { // 02
      // 국번이 0이나 1로 시작하지 않는다.
      if (telNo[2] === '0' || telNo[2] === '1') {
        return telNo.substr(0, 2);
      }

      if (telNo.length > 9) {
        return `${telNo.substr(0, 2)}-${telNo.substr(2, 4)}-${telNo.substr(6, 4)}`;
      } else if (telNo.length > 5) {
        return `${telNo.substr(0, 2)}-${telNo.substr(2, 3)}-${telNo.substr(5, 4)}`;
      } else if (telNo.length > 2) {
        return `${telNo.substr(0, 2)}-${telNo.substr(2, 3)}`;
      } else {
        return telNo;
      }
    }
  }

  /**
   * deliveryType의 값에 따라서 결과가 다른다.
   */
  userTelValidator(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      // this.orderForm은 this가 변할 경우에 undefined가 되는 경우가 있다.
      const userTel = control.value;

      if (userTel && userTel.length > 0) {
        const match = userTel.match(/^(0[157]0[1-9]?-[1-9][0-9]{3,4}-[0-9]{4}|02-[2-9][0-9]{2,3}-[0-9]{4})$/);
        if (match === null) {
          return { reason: '전화번호가 형식에 맞지 않습니다.'};
        }
      } else {
        return { reason: '전화번호가 필요합니다.'};
      }
    };
  }

  /**
   * 모든 control이 변결될 때마다 호출된다.
   * userTel과 deliveryType의 변경때 마다 userTel의 validator를 실행시키기 위해 사용한다.
   */
  formValidator(): ValidatorFn {
    return (control: FormGroup): ValidationErrors | null => {

      const orderForm = control;
      if (orderForm) {
        // onlySelf를 false로 하면 무한반복한다.
        orderForm.get('userTel').updateValueAndValidity({onlySelf: true});
        orderForm.get('address_jibun').updateValueAndValidity({onlySelf: true});
      }

      return null;
    };
  }

  /**
   * inputAddressMethod 값에 따라서 결과가 다른다.
   */
  addressJibunValidator(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      // 초기에 undefined로 되는 경우가 있다.
      if (control.parent === undefined) {
        return null;
      }

      const jibun = control.value;
      const addressForm = control.parent;
      const validJibun = this.validJibun.get(addressForm.get('address_dong').value);

      if (addressForm.get('address_jibun').errors) {
        if (addressForm.get('address_jibun').errors.pattern) {
          return { reason: '형식이 맞지 않습니다!'};
        } else if (addressForm.get('address_jibun').errors.outOfRegion) {
          return addressForm.get('address_jibun').errors.outOfRegion;
        } if (jibun.length > 0 && validJibun) {
          const jibunPrefix = Number(jibun.split('-')[0]);
          let isValid = false;
          validJibun.forEach(range => {
            if ((range.min <= jibunPrefix) && (range.max >= jibunPrefix)) {
              isValid = true;
            }
          })

          if (!isValid) {
            return { reason: '배달가능한 주소가 아닙니다.'};
          }
        }
      }

      return null;
    };
  }

  async augmentAddress() {
    this.updateModel();

    const {address_sido, address_sigungu, address_dong, address_jibun, address_detail} = this.unifiedOrder;

    try {
      const resMessage = await this.messageService.requestAugmentAddress(`${address_sido} ${address_sigungu} ${address_dong} ${address_jibun} ${address_detail}`) as Message<'response', 'augmentAddress'>;
      
      if (resMessage.result === 'success') {
        this.updateAddress(resMessage);
      } else {
        // this.logService.info(`주문실패 : ${resMessage.reason}`);
        throw new Error(`잘못된 주소입니다.`);
      }
    } catch (error) {
      // this.logService.error(`${error.message}`);
      if (error.name === 'TimeoutError') {
        throw new Error('시간 초과 : 명령에 응답을 하지 않습니다.');
      } else {
        throw new Error(error.message);
      }
    }
  }

  /**
   * augmentAddress 메시지 응답을 반영한다.
   */
  updateAddress(message: Message<'response', 'augmentAddress'>) {
    const sido = message.body.sido;
    const sigungu = message.body.sigungu;
    const dong = message.body.dong;
    const jibun = message.body.jibun;

    this.unifiedOrder = {...this.unifiedOrder, ... {
      address_key: message.body.key,
      address_dongH: message.body.dongH,
      address_road: message.body.road,
      address_building_name: message.body.building_name,
      address_location: message.body.location,
      address_sido: sido,
      address_sigungu: sigungu,
      address_dong: dong,
      address_jibun: jibun,
      vroong: message.body.vroong,
    }};

    if (this.unifiedOrder.address_dongH === undefined) {
      throw new Error('지번을 확인해주세요.');
    }
  }
  /*******************************************************************
   * end of copy
   *******************************************************************/

  /**
   * 전화번호에 해당하는 오늘자 UnifiedOrder목록 조회
   */
  async getUnifiedOrders(userTel: string) {
    return new Promise<UnifiedOrderDoc[]>((resolve) => {
      this.unifiedOrderService.observeMangoOrder(userTel).subscribe(unifiedOrder => {
        if (unifiedOrder) {
          resolve(unifiedOrder);
        }
      });
    })
  }

  /**
   * 같은 번호로 이미 주문한 내역이 있는지 확인
   */
  async checkIsAbusing(userTel: string) {
    try {
      const unifiedMangoOrder = await this.getUnifiedOrders(userTel);

      if (unifiedMangoOrder.length > 0) {
        const isCanceled = unifiedMangoOrder.findIndex(elem => {
          return ((elem.orderStatusCode !== UnifiedOrderStatusCode.CANCELED) && 
            (elem.orderStatusCode !== UnifiedOrderStatusCode.DELETED))
        });
  
        if (isCanceled > -1) {
          throw new Error('abuse');
        }
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }
  
  /*******************************************************************
   * 주문
   */
  async order() {
    this.logService.info(`OrderForm : 버튼 '주문하기'`);

    if (this.unifiedOrderFoods.length === 0) {
      this.alertNoticeService.noticeAlert('메뉴가 비었어요.');
      this.logService.error(`주문실패 : 메뉴정보 없음`);
      return;
    }

    if (this.isClosed) {
      await this.alertNoticeService.noticeAlertConfirm(this.msgClosed, () => this.goHome());
      this.logService.error(`주문실패 : ${this.msgClosed}`);
      return;
    }

    await this.loadingService.presentLoading();

    // 같은 번호로 같은 날 동일 주문인지 확인
    const { userTel } = this.unifiedOrder;
    try {
      await this.checkIsAbusing(userTel);
    } catch (error) {
      await this.loadingService.dismissLoading();
      if (error.message === 'abuse') {
        this.alertNoticeService.noticeAlertConfirm('하루에 한 번만 참여 가능합니다.', () => this.goHome()); 
      } else {
        this.alertNoticeService.noticeAlert('시스템 오류로 주문에 실패하였습니다.');
      }
      this.logService.error(`주문실패 : [${userTel}] ${error.message}`);
      return;
    }

    try {
      await this.augmentAddress();
      await this.createOrder();      
      this.gtagService.page('/buy');

      this.logService.info(`주문성공`);
      await this.alertNoticeService.noticeAlertConfirm('잠시 후 접수가 완료되면 예상 배달 시간을 문자로 알려드립니다.', () => this.goHome());
    } catch (error) {
      this.logService.error(`${error.message}`);
      await this.alertNoticeService.noticeAlert(`${error.message}`);
    } finally {
      await this.loadingService.dismissLoading();
    }
  }
  
  async createOrder() {
    this.updateModel();

    this.unifiedOrder.foods = this.unifiedOrderFoods;
    this.unifiedOrder.orderAmount = this.unifiedOrderFoods.reduce((sum, food) => sum + food.foodOrdPrice, 0);
    this.unifiedOrderContext.eventDiscount = this.unifiedOrder.orderAmount;
    this.unifiedOrder.orderDate = fecha.format(new Date(), 'YYYY-MM-DDTHH:mm:ss+0900');

    await this.unifiedOrderService.createMangoOrder(this.unifiedOrder, this.unifiedOrderContext);
    
    this.initModel();
    this.resetForShop();

  }

  /*******************************************************************
   * 전화번호 문자인증
   */
  async sendAuthSMS() {
    this.updateModel();
    const { userTel } = this.unifiedOrder;

    try {
      await this.loadingService.presentLoading();
      const response = await get_request_auth(userTel);
      this.gtagService.page('/req-auth');

      const { sessionId } = await response.json() as { sessionId: string };
      this.sessionId = sessionId;
      this.logService.info(`문자전송 성공 ${userTel}`);
    } catch (error) {
      await this.toastNoticeService.noticeToast(error);
      this.logService.error(`문자전송 실패 ${userTel} : ${error}`);
    } finally {
      await this.loadingService.dismissLoading();
    }
  }

  async confirmSMSCode() {
    const authCode = this.orderForm.get('authCode').value;
    const { userTel } = this.unifiedOrder;
    
    try {
      this.loadingService.presentLoading();
      const response = await get_confirm_auth(this.sessionId, authCode);
      this.gtagService.page('/confirm-auth');

      const { result, reason } = await response.json() as { result: string, reason: string| null };
      this.confirmAuth = (result === 'success');
      
      if (reason !== null) {
        let msg = (reason === 'autoCode mismatch') ? '잘못된 인증문자입니다.<br>다시 입력해주세요.' : reason;
        await this.toastNoticeService.noticeToast(msg);
        this.logService.error(`문자인증 실패 ${userTel} : ${reason}`);
      } else {
        this.logService.info(`문자인증 성공 ${userTel}`);
      }
      
    } catch (error) {
      await this.logService.error(`문자인증 실패 ${userTel} : ${error}`);
      this.toastNoticeService.noticeToast(error);
    } finally {
      await this.loadingService.dismissLoading();
    }
  }

  goHome() {
    this.shoppingCartService.emptyCart();
    this.router.navigate([this.currentSite], { replaceUrl: true });
  }
}