import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
// import { MyMaterialModule } from '../my-material/my-material.module';


import { SharedModule } from '../shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
// import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    // MyMaterialModule,
    IonicModule,
    SharedModule,
    AuthRoutingModule
  ],
  declarations: [
    // SignupComponent, 
    LoginComponent
  ]
})
export class AuthModule { }
