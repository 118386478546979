import * as uuidv4 from 'uuid/v4';
import { race, NEVER } from 'rxjs';
import { map, timeout, filter, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { firestore } from 'firebase/app';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import { environment } from '../../environments/environment';
import { BaeminCancelReasonCode } from './schema-baemin-api';
import {
  Message,
  MessagePeer,
  MessageBodyMap,
  RequestMessageBodyCreateVroongDelivery,
} from './schema-message';
import { UtilService } from './util.service';
import { UserService } from './user.service';

const collectionPath = environment.firestoreCollectionPath.message;

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  // 리로드하기 전까지는 유지된다.
  instanceId: string;

  constructor(
    private db: AngularFirestore,
    private utilService: UtilService,
    private userService: UserService
  ) {
    this.instanceId = uuidv4();
    console.log(`message instance ID = ${this.instanceId}`);
  }

  /**
   * 지정 시각 이후의 메시지 전체를 받는다.
   */
  observeMessage() {
    const now = new Date();
    // const from = now.getTime() - 10 * 24 * 3600 * 1000;

    console.log(`${this.constructor.name}::observeMessage from ${new Date()}`);
    const queryFn: QueryFn = ref => {
      return ref
        .where('channel', '==', 'message')
        .where('type', '==', 'response')
        .where('to.class', '==', 'mango')
        .where('to.instanceNo', '==', this.instanceId)
        .where('_timeCreate', '>', new Date());
    };

    const messageCollection = this.db.collection<Message<'response', any>>(collectionPath, queryFn);

    messageCollection
      .stateChanges()
      .pipe(
        map(actions =>
          actions.map(action => {
            // _type 필드 추가
            return { _type: action.type, ...action.payload.doc.data() };
          })
        )
      )
      .subscribe(messages => {
        for (const message of messages) {
          if (message._type === 'added') {
            console.log(`message response received : ${message.name}`);
            this.handleResponse(message);
          } else {
            console.error(`${message.name} ${message._type}`);
          }
        }
      }, error => {
        this.utilService.toastrError(`observeMessage에서 에러 발생 : ${error}`);
      });
  }

  /**
   * requestId로 요청한 메시지의 응답을 지정 시간까지 기다린다.
   *
   * @param requestId request document의 ID
   * @param msec 밀리초
   */
  async observeResponseWithTimeout(requestId: string, msec = 10000) {
    // 복합 색인을 피하기 위해서 requestId에 대해서만 조건을 주었다.
    const queryFn: QueryFn = ref => {
      return ref
        .where('requestId', '==', requestId);
    };

    const messageCollection = this.db.collection<Message<'response', any>>(collectionPath, queryFn);

    return new Promise((resolve, reject) => {
      // refer: https://stackoverflow.com/questions/46886073/rxjs-timeout-to-first-value
      // race는 2개의 observable 중에 1개의 observable을 선택하는 것이지 한 개의 값을 취하는 것이 아니다.
      // 그렇기 때문에 complete이 되는 것도 아니다.
      // complete이 되게 하기 위해서
      // 1. filter로 원치 않는 응답은 거르고
      // 2. take()로 1개만 취했다.
      const messageOb = messageCollection
        .stateChanges()
        .pipe(
          map(actions =>
            actions.map(action => {
              // _type 필드 추가
              return { _type: action.type, ...action.payload.doc.data() };
            })
          ),
          filter(messages => messages.length > 0),  // 최초의 빈 배열은 거른다.
          take(1)
        );
      const timeoutOb = NEVER.pipe(timeout(msec));

      race(messageOb, timeoutOb).subscribe(messages => {
          console.log(`next :`);
          console.dir(messages);

          for (const message of messages) {
            if (message._type === 'added') {
              console.log(`message response for ${requestId}/${message.name} received`);
              resolve(message);
            } else {
              console.error(`${message.name} ${message._type}`);
            }
          }
        }, error => {
          // timeout인 경우에는 다음의 형식을 리턴
          // {
          //   message: "Timeout has occurred"
          //   name: "TimeoutError"
          //   stack: "
          // }
          if (error.name === 'TimeoutError') {
            error.message = `응답 대기 시간이 ${msec / 1000}초를 초과했습니다.`;
            console.log(`observeResponse(${requestId}) Tiemout`);
          } else {
            console.dir(error);
            this.utilService.toastrError(`observeMessage에서 에러 발생 : ${error}`);
          }
          reject(error);
        }, () => {
          console.log(`observeResponse(${requestId}) complete`);
        });
    });
  }

  handleResponse(message: Message<'response', any>) {
    let toastrMessage;
    switch (message.name) {
      case 'acceptBaeminOrder':
        toastrMessage = `배민 주문 접수 : ${message.body.orderNo}`;
        break;
      case 'completeBaeminOrder':
        toastrMessage = `배민 주문 완료 : ${message.body.orderNo}`;
        break;
      case 'cancelBaeminOrder':
        toastrMessage = `배민 주문 취소 : ${message.body.orderNo}`;
        break;
      case 'getBaeminBlock':
        // 대화 상자에서 결과를 바로 확인할 수 있고 에러는 별도로 표시하므로 toastr를 보여주지 않는다.
        return;
      case 'postBaeminBlock':
        // 대화 상자에서 결과를 바로 확인할 수 있고 에러는 별도로 표시하므로 toastr를 보여주지 않는다.
        return;
      case 'postBaeminUnblock':
        // 대화 상자에서 결과를 바로 확인할 수 있고 에러는 별도로 표시하므로 toastr를 보여주지 않는다.
        return;

      case 'acceptFoodflyOrder':
        toastrMessage = `푸플 주문 접수 : ${message.body.orderNo}`;
        break;

      case 'acceptCoupangeatsOrder':
        toastrMessage = `쿠팡이츠 주문 접수 : ${message.body.orderId}`;
        break;

      case 'readyCoupangeatsOrder':
        toastrMessage = `쿠팡이츠 조리 완료 : ${message.body.orderId}`;
        break;

      case 'createVroongDelivery':
        toastrMessage = `배차 요청 : ${message.body.client_order_no}`;
        break;
      case 'cancelVroongDelivery':
        toastrMessage = `배송 취소 : ${message.body.delivery_id}`;
        break;
      case 'preparedCargoVroongDelivery':
        toastrMessage = `조리 완료 : ${message.body.deliveryId}`;
        break;
      case 'estimateVroongDelivery':
        // 대화 상자에서 결과를 바로 확인할 수 있고 에러는 별도로 표시하므로 toastr를 보여주지 않는다.
        return;
        // toastrMessage = `완료 예상 시간 : ${message.body.delivery_number}`;
        break;

      case 'restartQZTray':
        toastrMessage = `프린터 서버 재시작 : ${message.body.site}`;
        break;
      case 'augmentAddress':
        toastrMessage = `주소 확인 : ${message.body ? message.body.road : ''}`;
        break;
      default:
        toastrMessage = `알 수 없는 메시지 : ${message.name}`;
        break;
    }

    if (message.result === 'success') {
      this.utilService.toastrInfo(toastrMessage, '성공', 5000);
    } else if (message.result === 'error') {
      this.utilService.toastrError(`${toastrMessage}\n${message.reason ? message.reason : '원인 불명'}`, '실패', 30000);
    } else {
      this.utilService.toastrError(`이런 result : ${message.result}`, '실패', 600000);
    }
  }

  private async request<N extends keyof MessageBodyMap['request']>(
    name: N,
    to: MessagePeer,
    body: MessageBodyMap['request'][N]
  ) {
    // message Id는 firestore가 제공하는 Id를 이용한다.
    const docRef = this.db.firestore.collection(collectionPath).doc();
    const docId = docRef.id;

    const cmd: Message<'request', N> = {
      _id: docId,
      _timeCreate: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,
      channel: 'message',
      from: {
        class: 'mango',
        instanceNo: this.instanceId,
        account: this.userService.user.email
      },
      to,
      type: 'request',
      name,
      body,
    };

    await this.db.doc<Message<'request', N>>(docRef).set(cmd);
    const message = await this.observeResponseWithTimeout(docId);

    return message;
  }

  private async notification<N extends keyof MessageBodyMap['notification']>(
    name: N,
    body: MessageBodyMap['notification'][N],
    email?: string
  ) {
    // message Id는 firestore가 제공하는 Id를 이용한다.
    const docRef = this.db.firestore.collection(collectionPath).doc();
    const docId = docRef.id;

    const cmd: Message<'notification', N> = {
      _id: docId,
      _timeCreate: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,
      channel: 'message',
      from: {
        class: 'mango',
        instanceNo: this.instanceId,
        account: email ? email : this.userService.user.email
      },
      // to,
      type: 'notification',
      name,
      body,
    };

    return await this.db.doc<Message<'notification', N>>(docRef).set(cmd);
  }

  async requestAcceptBaeminOrder(instanceNo: string, orderNo: string, deliveryMinutes: number) {
    return await this.request('acceptBaeminOrder', {
      class: 'baemin-app-proxy',
      instanceNo
    }, {
      orderNo,
      deliveryMinutes
    });
  }

  async requestCancelBaeminOrder(instanceNo: string, orderNo: string, cancelReasonCode: BaeminCancelReasonCode) {
    return await this.request('cancelBaeminOrder', {
      class: 'baemin-app-proxy',
      instanceNo
    }, {
      orderNo,
      cancelReasonCode
    });
  }

  async requestCompleteBaeminOrder(instanceNo: string, orderNo: string) {
    return await this.request('completeBaeminOrder', {
      class: 'baemin-app-proxy',
      instanceNo
    }, {
      orderNo
    });
  }

  /**
   * 배민의 영업운영중지 상태를 조회환다.
   */
  async requestGetBaeminBlock(instanceNo: string) {
    return await this.request('getBaeminBlock', {
      class: 'baemin-app-proxy',
      instanceNo
    }, {
    });
  }
  /**
   * 배민 영업운영중지 설정
   * @param temporaryBlockTime 분
   */
  async requestPostBaeminBlock(instanceNo: string, temporaryBlockTime: number) {
    return await this.request('postBaeminBlock', {
      class: 'baemin-app-proxy',
      instanceNo
    }, {
      temporaryBlockTime
    });
  }
  /**
   * 배민 영업운영중지 해제
   */
  async requestPostBaeminUnblock(instanceNo: string) {
    return await this.request('postBaeminUnblock', {
      class: 'baemin-app-proxy',
      instanceNo
    }, {
    });
  }

  async requestAcceptFoodflyOrder(instanceNo: string, orderNo: string) {
    return await this.request('acceptFoodflyOrder', {
      class: 'foodfly-ceo-proxy',
      instanceNo
    }, {
      orderNo
    });
  }

  /**
   * 쿠팡이츠
   */
  async requestAcceptCoupangeatsOrder(instanceNo: string, orderId: string, duration: string) {
    return await this.request('acceptCoupangeatsOrder', {
      class: 'coupangeats-app-proxy',
      instanceNo
    }, {
      orderId,
      duration
    });
  }

  async requestReadyCoupangeatsOrder(instanceNo: string, orderId: string) {
    return await this.request('readyCoupangeatsOrder', {
      class: 'coupangeats-app-proxy',
      instanceNo
    }, {
      orderId
    });
  }

  /**
   * 부릉 배차를 메시지를 통해 명령한다.
   */
  async requestCreateVroongDelivery(instanceNo: string, body: RequestMessageBodyCreateVroongDelivery) {
    return await this.request('createVroongDelivery', {
      class: 'vroong-pos-proxy',
      instanceNo
    }, body);
  }

  /**
   * 부릉 배차 취소를 메시지를 통해 명령한다.
   */
  async requestCancelVroongDelivery(instanceNo: string, deliveryId: string) {
    return await this.request('cancelVroongDelivery', {
      class: 'vroong-pos-proxy',
      instanceNo
    }, {
      deliveryId
    });
  }

  /**
   * 부릉 조리 완료를 메시지를 통해 명령한다.
   */
  async requestPreparedCargoVroongDelivery(instanceNo: string, deliveryId: string) {
    return await this.request('preparedCargoVroongDelivery', {
      class: 'vroong-pos-proxy',
      instanceNo
    }, {
      deliveryId
    });
  }

  /**
   * 부릉 조리 완료를 메시지를 통해 명령한다.
   */
  async requestEstimateVroongDelivery(instanceNo: string, deliveryId: string) {
    return await this.request('estimateVroongDelivery', {
      class: 'vroong-pos-proxy',
      instanceNo
    }, {
      deliveryId
    });
  }

  async restartQZTray(instanceNo: string) {
    return await this.request('restartQZTray', {
      class: 'printer-agent',
      instanceNo
    }, {
      site: instanceNo
    });
  }

  async requestAugmentAddress(rawAddress: string) {
    return await this.request('augmentAddress', {
      class: 'order-hub',
      instanceNo: 'common'
    }, {
      rawAddress
    });
  }

  async notificationLogin(email: string, body: any = null) {
    return await this.notification('login', body, email);
  }

  async notificationLogout() {
    return await this.notification('logout', null);
  }

  async notificationLog(msg: string, context: any = null) {
    return await this.notification('log', {
      msg,
      context
    });
  }
}
