import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertNoticeService {

  constructor(
    private alertController: AlertController
  ) { }
  
  async noticeAlert(msg: string, msg2: string = null, timeout?: number) {
    const alert = await this.alertController.create({
      // header: 'Alert',
      subHeader: msg2,
      message: msg,
      buttons: ['확인']
    });

    await alert.present();
    
    if (timeout !== undefined) {
      setTimeout(() => {
        alert.dismiss();
      }, timeout);
    }
  }

  async noticeAlertConfirm(msg: string, okay: () => void, timeout?: number) {
    const alert = await this.alertController.create({
      // header: 'Confirm!',
      message: msg,
      backdropDismiss: false,
      // message: 'Message <strong>text</strong>!!!',
      buttons: [
        // {
        //   text: '취소',
        //   role: 'cancel',
        //   cssClass: 'secondary',
        //   handler: () => alert.dismiss
        // }, 
        {
          text: '확인',
          handler: okay
        }
      ]
    });

    await alert.present();
    
    if (timeout !== undefined) {
      setTimeout(() => {
        alert.dismiss();
      }, timeout);
    }
  }
}
