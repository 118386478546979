import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SitesComponent } from './sites/sites.component';
import { IonicModule } from '@ionic/angular';
import { FormModule } from '../form/form.module';
import { LandingComponent } from '../landing.component';
import { ErrorComponent } from './error/error.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    FormModule
  ],
  declarations: [
    SitesComponent, 
    LandingComponent,
    ErrorComponent
  ]
})
export class PagesModule { }
