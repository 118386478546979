import fecha from 'fecha';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    private toastr: ToastrService
  ) { }

  toastrInfo(message: string, title?: string, timeout?: number) {
    const now = fecha.format(new Date(), `HH:mm:ss`);
    this.toastr.info(`[${now}] ${message}`, title, {
      timeOut: timeout ? timeout : 10000
      // disableTimeOut: true
    });
  }

  toastrWarning(message: string, title?: string, timeout?: number) {
    const now = fecha.format(new Date(), `HH:mm:ss`);
    this.toastr.warning(`[${now}] ${message}`, title, {
      timeOut: timeout ? timeout : 30000
      // disableTimeOut: true
    });
  }

  toastrError(message: string, title?: string, timeout?: number) {
    const now = fecha.format(new Date(), `HH:mm:ss`);
    this.toastr.error(`[${now}] ${message}`, title, {
      timeOut: timeout ? timeout : 30000
      // disableTimeOut: true
    });
  }

  toastrCatch(reason: string) {
    const now = fecha.format(new Date(), `HH:mm:ss`);
    this.toastr.error(`[${now}] ${reason}`, '예외 발생', {
      timeOut: 30000
    });
  }
}
