import { BehaviorSubject, combineLatest } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  CanActivate, Route,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild, CanLoad
} from '@angular/router';
// import { debugLog } from './core/util';
import { UserService } from './core/user.service';
import { ConfService } from './core/conf.service';

/**
 * 개별 페이지를 표시하기 전에 준비해야 할 것이 있으면 기다리도록 한다.
 * - conf/organization
 */
@Injectable({
  providedIn: 'root'
})
export class InitGuardService implements CanActivate, CanActivateChild, CanLoad {
  loader = new BehaviorSubject<boolean>(true);

  constructor(
    private confService: ConfService,
    private userService: UserService,
  ) { }

  promiseForInit() {
    return new Promise<boolean>((resolve) => {
      const userOb = this.userService.latestUserSubject;
      const mangoConf = this.confService.latestMangoConfSubject;

      combineLatest([userOb, mangoConf]).subscribe(([user, mangoConf]) => {
        if (user && mangoConf) {
          this.loader.next(false);
          resolve(true);
        }
      });
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // debugLog(`canActivate: '${route.url}'`);

    return this.promiseForInit();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // debugLog(`canActivateChild: '${childRoute.url}'`);

    return this.promiseForInit();
  }

  canLoad(route: Route) {
    // debugLog(`canLoad: '${route.path}'`);

    return this.promiseForInit();
  }
}

