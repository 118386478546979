import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { GtagService } from './core/gtag.service';

/**
 * GA의 캠페인의 첫 페이지
 * 1. utm_xxx 등의 내용을 없애기 위해서 바로 리다이렉트 한다.
 * 2. 바로 리다이렉트하지 못하는 경우에 GA가 제대로 query parameter를 캐치하지 못 하기 때문에 gtag 명령으로 직접 추가한다.
 *
 * @export
 * @class LandingComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-landing',
  template: `
  `,
  styles: []
})
export class LandingComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private gtagService: GtagService
  ) { }

  ngOnInit() {
    //
    // unsubscribe는 하지 않아도 된다.
    this.route.queryParamMap.subscribe((paramMap: ParamMap) => {
      console.log(`params : ${paramMap.keys}`);
      // console.dir(paramMap);

      const medium = paramMap.get('utm_medium');
      const source = paramMap.get('utm_source');
      const name   = paramMap.get('utm_campaign');

      if (medium && source) {
        this.gtagService.campaign(medium, source, name);
      }
    });
    this.router.navigate(['/']);
  }
}
