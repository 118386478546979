export const roleMappings = {
  admin: '관리자',
  operator: '접수자',
  ceo: '사장님',
};

export const unifiedOrderStatusMappings = {
  0: '이상상태',
  10: '신규주문',
  15: '업소접수',
  17: '배차대기',
  20: '주문접수',
  30: '조리시작',
  40: '조리완료',
  50: '포장완료',
  60: '배송중',
  70: '배송완료',
  80: '취소',
  90: '삭제',
  95: '결제대기'
};

export const unifiedOrderVendorMappings = {
  baemin:       '배민',
  foodfly:      '푸플',
  yogiyo:       '요기요',
  ubereats:     '우버이츠',
  ddingdong:    '띵동',
  coupangeats:  '쿠팡이츠',
  shuttle:      '셔틀',
  ghostkitchen: '고스트키친',
  other:        '기타',
};

export const unifiedOrderChannelMappings = {
  app:  '앱',
  tel:  '전화',
  face: '직접방문'
};

export const unifiedOrderDeliveryTypeMappings = {
  DELIVERY:  '배달',
  TAKEOUT:   '테이크아웃',
  FOODFLY:   '푸드플라이',
  BAERA:     '배라',
  DDINGDONG: '띵동',
  UBER:      '우버이츠',
  COUPANG:   '쿠팡이츠',
  SHUTTLE:   '셔틀',
  HERE:      '매장식사'
};

export const unifiedOrderCreatedByMappings = {
  manual: '직접입력',
  face: '고객입력'
};

export const baeminAppListPaymentMethodMappings = {
  1: '만나서현금',
  2: '만나서카드',
};

export const baeminAppPaymentGroupMappings = {
  1: '선불',
  2: '후불'
};

export const baeminAppDetailPaymentMethodMappings = {
  1: '카드',
  2: '휴대폰결제',
  5: '만나서현금',
  6: '만나서카드',
  12: '페이코',
  13: '배민페이 계좌이체',
  14: '배민페이',
  15: '토스',
  16: '네이버페이',
  17: '카카오페이',
  30: '네이버페이2', // T0G20000076D 16과 구별하기 위해서 네이버페이2라고 했다.
  99: 'OK캐시백' // 2019-07-19. 100%할인 받은 경우일 수도 있다.
};

export const baeminCeoStatusMappings = {
  OPENED:    '신규',
  ACCEPTED:  '주문접수',
  CLOSED:    '배송완료',
  CANCELLED: '취소'
};

export const baeminCeoCategoryCodeMappings = {
  1: '치킨',
  2: '중식',
  3: '피자',
  4: '족발,보쌈',
  5: '야식',
  6: '찜,탕',
  7: '패스트푸드',
  9: '도시락',
  10: '돈까스,회,일식',
  32: '한식',
  33: '분식',
  34: '카페,디저트'
};

export const baeminCeoPurchaseTypeMappings = {
  BARO: '선불',
  MEET: '후불'
};

export const vroongStatusMappings = {
  SUBMITTED: '배차대기',
  ASSIGNED:  '픽업중',
  PICKED_UP: '배송중',
  DELIVERED: '배송완료',
  CANCELED:  '취소',
};

export const vroongPayMethodMappings = {
  PREPAID: '선불',
  AT_DEST_CARD: '후불카드',
  AT_DEST_CASH: '후불현금'
};

export const foodflyAcceptStatusMappings = {
  0:  '신규',
  1:  '미접수', // 접수하지 않았는데 취소된 경우로 추정
  3:  '주문접수',
  5:  '조리완료',
  98: '취소',
  99: '주문거절'
};

export const foodflySendStatusMappings = {
  0: '미배차',
  1: '배차대기',
  2: '배차완료',
  3: '업장도착',
  4: '픽업',
  5: '배달완료',
  6: '취소'
};

export const coupangeatsOrderStatusMappings = {
  PAYMENT_APPROVED: '신규',
  ACCEPTED: '주문접수',
  PICKED_UP: '배송중',
  COMPLETED: '배송완료',
  CANCELLED: '취소'
};

export const coupangeatsDeliveryStatusMappings = {
  MERCHANT_ACCEPTED: '배차대기',
  COURIER_ACCEPTED: '픽업중',
  MERCHANT_READY: '조리완료',
  COURIER_PICKEDUP: '배송중',
  DELIVERED: '배송완료',
  CANCELLED: '취소'
};

export const combinenetStateCodeMappings = {
  1: '접수',
  2: '배차',
  3: '픽업',
  5: '배달완료',
  44: '취소'
};

export const combinenetPayMethodMappings = {
  0: '후불현금',
  1: '후불카드',
  2: '선불'
};

export const roomPrinterMappings = {
  samsung01: '삼성점 1호',
  samsung02: '삼성점 2호',
  samsung03: '삼성점 3호',
  samsung04: '삼성점 4호',
  samsung05: '삼성점 5호',
  samsung06: '삼성점 6호',
  samsung07: '삼성점 7호',
  samsung08: '삼성점 8호',
  samsung09: '삼성점 9호',
  samsung10: '삼성점 10호',
  samsung11: '삼성점 11호',
  samsung12: '삼성점 12호',
  samsung13: '삼성점 13호',
  samsung14: '삼성점 14호',
  samsung15: '삼성점 15호',
  samsungDesk: '삼성점 데스크',
  kangnam01: '강남역점 1호',
  kangnam02: '강남역점 2호',
  kangnam03: '강남역점 3호',
  kangnam04: '강남역점 4호',
  kangnam05: '강남역점 5호',
  kangnam06: '강남역점 6호',
  kangnam07: '강남역점 7호',
  kangnam08: '강남역점 8호',
  kangnam09: '강남역점 9호',
  kangnam10: '강남역점 10호',
  kangnam11: '강남역점 11호',
  kangnam12: '강남역점 12호',
  kangnam13: '강남역점 13호',
  kangnam14: '강남역점 14호',
  kangnam15: '강남역점 15호',
  kangnam16: '강남역점 16호',
  kangnam17: '강남역점 17호',
  kangnam18: '강남역점 18호',
  kangnam19: '강남역점 19호',
  kangnam20: '강남역점 20호',
  kangnam21: '강남역점 21호',
  kangnam22: '강남역점 22호',
  kangnam23: '강남역점 23호',
  kangnam24: '강남역점 24호',
  kangnam25: '강남역점 25호',
  kangnam26: '강남역점 26호',
  kangnam27: '강남역점 27호',
  kangnamDesk: '강남역점 데스크',
  NA: '??'
};

export const simpleNoticeMappings = {
  hide: '비활성화',
  show: '활성화',
  deleted: '삭제',
};
