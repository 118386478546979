import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { ShoppingCartService } from '../../core/shopping-cart.service';
import { VersionService } from '../../core/version.service';
import { LoadingService } from '../../shared/loading/loading.service';
import { ConfService } from '../../core/conf.service';
import { UnifiedMenuDoc, UnifiedMenuFood, MangoConf } from '../../core/schema';

import { AlertNoticeService } from '../../shared/alert-notice/alert-notice.service';
import { LogService } from '../../core/log.service';
import { EventList } from '../../core/schema-mango';
import * as eventData from '../../../kangnamEventList.json';

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss']
})
export class SitesComponent implements OnInit, OnDestroy {
  isClosed = true;
  msgClosed = '';
  activeKey = '';

  myVersion: string;
  latestVersion: string;
  
  prefix = 'https://ssproxy.ucloudbiz.olleh.com/v1/AUTH_d722d13e-44ea-44ad-8c9b-2f5763ce3d40/ghostkitchen/mango/';
  subscription: Subscription;
  unifiedMenu: UnifiedMenuDoc[];
  shops: EventList[] = [];
  eventPrice = 0;

  private versionSubscription: Subscription = null;

  constructor(
    private shoppingCartService: ShoppingCartService,
    private loadingService: LoadingService,
    private versionService: VersionService,
    private alertNoticeService: AlertNoticeService,
    private confService: ConfService,
    private logService: LogService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.myVersion = this.versionService.myVersion;
    this.latestVersion = this.versionService.latestVersion;

    this.versionSubscription = this.versionService.latestVersionSubject.subscribe(lastesVersion => {
      this.latestVersion = lastesVersion;
      if (this.myVersion !== this.latestVersion) {
        this.alertNoticeService.noticeAlertConfirm('새 버전이 있습니다.<br>업데이트합니다.', () => this.reload());
      }
    });

    this.initialize();
    this.loadingService.dismissLoading();
  }

  initialize() {
    this.subscription = this.confService.latestMangoConfSubject
      .subscribe((mangoConf) => {
        this.isClosed = mangoConf.isClosed;
        this.msgClosed = mangoConf.msgClosed;
        this.activeKey = mangoConf.activeKey;
        
        this.setEventShops(mangoConf, eventData.data as EventList[]);
      });
  }

  setEventShops(mango: MangoConf, eventList: EventList[]) {
    const newList: EventList[] = [];
    // Event중인 상점 목록 필터
    eventList.forEach(eventItem => {
      const onEvent = mango.eventShops.find(mango => mango.eventKey === eventItem.eventKey);
      if (onEvent !== undefined) {
        newList.push(eventItem);
      }
    });

    // 고정 메뉴의 금액 계산
    newList.forEach(item => {
      let foodPrice = 0;
      item.foods.forEach(food => {
        foodPrice += this.sum(food);
      })
      item.foodPrice = foodPrice;
    })

    this.shops = newList;
  }

  sum(food: UnifiedMenuFood) {
    let sum = 0;
    for (const foodOptGroup of food.foodOptGroups) {
      for (const foodOpt of foodOptGroup.foodOpts) {
        if (foodOpt._optQty > 0) {
          sum += foodOpt._optQty * foodOpt.optPrice;
        }
      }
    }

    return sum;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.versionSubscription) {
      this.versionSubscription.unsubscribe();
    }
  }

  order(shopNo: string, $event: any) {
    const buttonText = $event.target.innerText;
    const selectedShop = this.shops.find(shop => shop.shopNo === shopNo);
    
    selectedShop.foods.forEach(food => {
      this.shoppingCartService.addFood(food, shopNo, 1);
    });
    
    this.logService.info(`Site : 버튼 '${buttonText}'`);
    this.router.navigate(['order'], { relativeTo: this.route, state: { shop : selectedShop } });
  }

  reload() {
    this.logService.info(`Site : 버튼 '업데이트'`);
    window.location.reload();
  }
}
