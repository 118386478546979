import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { LoadingService } from '../loading/loading.service';

@Injectable({
  providedIn: 'root'
})
export class ToastNoticeService {

  constructor(
    private toastController: ToastController,
    private loadingService: LoadingService
  ) { }

  async noticeToast(msg: string, timeout?: number) {
    const toast = await this.toastController.create({
      message: msg,
      duration: timeout ? timeout : 2000
    });
    toast.present();
  }

  async noticeErrorToast(msg: string, timeout?: number) {
    const toast = await this.toastController.create({
      message: msg,
      duration: timeout ? timeout : 5000,
      color: 'danger'
    });
    this.loadingService.dismissLoading();
    toast.present();
  }
}
