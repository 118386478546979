import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';


/**
 * 앱의 처음 URL을 제한한다.
 * /cart나 /orderform으로 바로 접근하는 것을 허용하지 않는다.
 *
 * @export
 * @class FirstGuardService
 * @implements {CanActivate}
 */
@Injectable({
  providedIn: 'root'
})
export class FirstGuardService implements CanActivate {
  count = 0;

  constructor(
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // console.log(`[${this.count++}]FirstGuard`);
    // console.log(`url = ${route.url}`);
    // console.dir(route.url);
    // console.dir(route);

    this.count++;
    // 최초 URL (redirect 후의 URL)
    if (this.count === 1) {
      if (['store', 'welcome', 'landing'].indexOf(route.url[0].path) === -1) {
        console.log(`${route.url} is not allowed to enter`);
        this.router.navigate(['store']);
        return false;
      }
    }

    return true;
  }
}
