import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SafePipe } from './safe.pipe';

import { OrderContactComponent } from './order-contact/order-contact.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  declarations: [
    SafePipe,
    OrderContactComponent,
  ],
  exports: [
    SafePipe,
  ]
})
export class SharedModule { }
